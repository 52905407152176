<template>
  <div>    
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Einheit hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
          <Button
            label="Einheit löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 d-inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="getUnits"
        v-model:selection="selectedIOs"
        dataKey="key"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
        responsiveLayout="scroll"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div
            class="
              table-header
              d-flex flex-column flex-md-row jc-md-between
            "
          >
            <h5 class="mb-2 m-md-0 as-md-center">Einheiten</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="suchen..."
              />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="key" header="Key" :sortable="true"></Column>
        <Column field="label" header="Bezeichnung" :sortable="true"></Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editIO(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteIO(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="ioDialog"
      :style="{ width: '450px' }"
      header="Kategorien bearbeiten"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="field">
        <label for="key">Key</label>
        <InputText
          id="key"
          v-model.trim="ioDialogData.key"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.key }"
        />
        <small class="p-error" v-if="submitted && !ioDialogData.key"
          >Key <b>muss</b> ausgefüllt werden!</small
        >
      </div>
      <div class="field">
        <label for="label">Bezeichnung</label>
        <Textarea
          id="desc"
          v-model="ioDialogData.label"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIoDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <span style="font-family:LogFileFont; color: yellow;">{{ ioDialogData.label }}</span> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIoDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIosDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIosDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedIOs"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { findIndexByKey, mongoResponseToast } from "@/helpers";
import { FilterMatchMode } from "primevue/api";
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";

export default {
  name: "config_io_unit",
  data() {
    return {
      loading: false,
      ioDialog: false,
      deleteIoDialog: false,
      deleteIosDialog: false,
      ioDialogData: {},
      config_io: null,
      io_type: null,
      selectedIOs: null,
      filters: {},
      submitted: false,
      intval_Value: null,
      elementIndex: -1,
    };
  },
  created() {
    this.initFilters();
    this.$store.dispatch('types/loadUnitTypes');
  },
  mounted() {
  },
  beforeUnmount() {
    this.ioDialogData = null;
    this.filters = null,
    this.selectedIOs = null;
    this.submitted = null;
  },
  computed: {
    ...mapGetters({
      getUnits: 'types/getUnits',
      getUnitsId: 'types/getUnitsId'
    }),
  },
  watch: {
    getUnits: {
      handler: function () {
        if (this.getUnits) {
          this.loading = false;
        } else {
          this.loading = true;
        }
      }
    }
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        // group: {},
      };
      this.elementIndex = -1;
    },
    getPageData() {
      this.$store.dispatch('types/loadUnitTypes', true);
    },
    openNew() {
      this.resetDialogData();
      this.submitted = false;
      this.ioDialog = true;
    },
    hideDialog() {
      this.ioDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
    },
    saveIO() {
      this.submitted = true;

      if (this.ioDialogData.key.trim()) {
        const newObj = [ ... this.getUnits ];
        if (this.elementIndex >= 0) {
          newObj[this.elementIndex] = { ...this.ioDialogData };
        } else {
          const elementIndex = findIndexByKey(this.getUnits, this.ioDialogData.key);
          if (elementIndex >= 0) {
            newObj[elementIndex] = this.ioDialogData;
          } else {
            newObj.push(this.ioDialogData);
          }
        }
        this.loading = true;
        const data = {
          _id: this.getUnitsId,
          data: newObj
        }
        SocketioService.setUnitTypes(data, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
        this.ioDialog = false;
        this.resetDialogData();
      }
    },
    editIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.elementIndex = findIndexByKey(this.getUnits, this.ioDialogData.key);
      console.log(this.elementIndex);
      this.ioDialog = true;
    },
    confirmDeleteIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteIoDialog = true;
    },
    deleteIO() {
      const newObj = [ ... this.getUnits ];
      const index = findIndexByKey(newObj, this.ioDialogData.key);
      if (index >= 0) {
        newObj.splice(index, 1);
        console.log(newObj);
        this.loading = true;
        const data = {
          _id: this.getUnitsId,
          data: newObj
        }
        SocketioService.setUnitTypes(data, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.deleteIoDialog = false;
      this.resetDialogData();
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteIosDialog = true;
    },
    deleteSelectedIOs() {
      if (this.selectedIOs.length > 0) {
        const newObj = [ ... this.getUnits ];
        const newArr = newObj.filter((obj) => {
          return !this.selectedIOs.some((delObj) => {
            return obj.key === delObj.key;
          });
        });
        this.loading = true;
        const data = {
          _id: this.getUnitsId,
          data: newArr
        }
        SocketioService.setUnitTypes(data, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.deleteIosDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>